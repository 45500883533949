import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LoginPage from './LoginPage';
import Dashboard from './Dashboard';
import QRCodePage from "./QRCodePage";
import QuestionDetail from "./QuestionDetail";

const App: React.FC = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<LoginPage />} />
                <Route path="/dashboard/:groupId" element={<Dashboard />} />
                <Route path="/question/:questionId" element={<QuestionDetail />} />
                <Route path="/qr-code/:questionTypeId" element={<QRCodePage />} />
            </Routes>
        </Router>
    );
};

export default App;
