import React, { useState, useEffect } from 'react';
import QRCode from 'react-qr-code';
import { useParams } from 'react-router-dom';

const QRCodePage: React.FC = () => {
    const { questionTypeId } = useParams<{ questionTypeId: string }>();
    const [hash, setHash] = useState<string | null>(null);

    const backendDomain = process.env.REACT_APP_BACKEND_DOMAIN;

    useEffect(() => {
        const fetchHash = async () => {
            const response = await fetch(`${backendDomain}/api/get-hash/${questionTypeId}`);
            if (response.ok) {
                const data = await response.json();
                setHash(data.hash);
            } else {
                alert('Failed to fetch hash');
            }
        };

        fetchHash();
    }, [questionTypeId]);

    if (!hash) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <h1>QR Code for Question Type {questionTypeId}</h1>
            <QRCode value={hash} />
        </div>
    );
};

export default QRCodePage;
