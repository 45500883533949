import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const LoginPage: React.FC = () => {
    const [hash, setHash] = useState('');
    const navigate = useNavigate();

    const backendDomain = process.env.REACT_APP_BACKEND_DOMAIN;

    const handleLogin = async () => {
        const response = await fetch(`${backendDomain}/api/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ hash })
        });

        if (response.ok) {
            const data = await response.json();
            navigate(`/dashboard/${data.groupId}`);
        } else {
            alert('Invalid group hash');
        }
    };

    return (
        <div>
            <input
                type="text"
                placeholder="Enter group hash"
                value={hash}
                onChange={(e) => setHash(e.target.value)}
            />
            <button onClick={handleLogin}>Login</button>
        </div>
    );
};

export default LoginPage;
