import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

interface Question {
    id: number;
    title: string;
    Options: Option[];
}

interface Option {
    id: number;
    title: string;
}

const QuestionDetail: React.FC = () => {
    const { groupId } = useParams<{ groupId: string }>();
    const { questionId } = useParams<{ questionId: string }>();
    const [question, setQuestion] = useState<Question | null>(null);
    const [selectedAnswer, setSelectedAnswer] = useState<number | null>(null);
    const navigate = useNavigate();

    const backendDomain = process.env.REACT_APP_BACKEND_DOMAIN;

    useEffect(() => {
        const fetchQuestion = async () => {
            const response = await fetch(`${backendDomain}/api/question/${questionId}`);
            if (response.ok) {
                const data = await response.json();
                console.log(data)
                setQuestion(data);
            } else {
                alert('Failed to fetch question details');
            }
        };

        fetchQuestion();
    }, [questionId]);

    const submitAnswer = async () => {
        if (selectedAnswer === null) {
            alert('Please select an answer');
            return;
        }
        const response = await fetch(`${backendDomain}/api/submit-answer`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                questionId: question!.id,
                answer: selectedAnswer,
                groupId: groupId
            })
        });

        if (response.ok) {
            console.log('Answer submitted!');
            setQuestion(null);
            setSelectedAnswer(null);
            navigate(`/dashboard/${groupId}`);
        } else {
            alert('Failed to submit answer!');
        }

    };

    if (!question) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <h1>{question.title}</h1>
            <ul>
                {question.Options.map(option => (
                    <li key={option.id} style={{listStyleType: "none"}}>
                        <input
                            type="radio"
                            value={option.id}
                            checked={selectedAnswer === option.id}
                            onChange={(e) => setSelectedAnswer(option.id)}
                        />
                        <label>{option.title}</label>
                    </li>
                ))}
            </ul>
            <button onClick={submitAnswer}>Submit</button>
        </div>
    );
};

export default QuestionDetail;
