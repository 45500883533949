import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { BrowserQRCodeReader } from '@zxing/browser';

interface Question {
    id: number;
    title: string;
}

const Dashboard: React.FC = () => {
    const { groupId } = useParams<{ groupId: string }>();
    const [answeredQuestions, setAnsweredQuestions] = useState<Question[]>([]);
    const [scannedQuestions, setScannedQuestions] = useState<Question[]>([]);
    const scannedQuestionsRef = useRef<Question[]>([]);  // Use a ref to track scanned questions
    const [isScanning, setIsScanning] = useState<boolean>(false);
    const videoRef = useRef<HTMLVideoElement>(null);
    const codeReaderRef = useRef<BrowserQRCodeReader | null>(null);

    const backendDomain = process.env.REACT_APP_BACKEND_DOMAIN;

    useEffect(() => {
        const fetchAnsweredQuestions = async () => {
            const response = await fetch(`${backendDomain}/api/answered-questions/${groupId}`);
            if (response.ok) {
                const data = await response.json();
                setAnsweredQuestions(data);
            } else {
                alert('Failed to fetch answered questions');
            }
        };

        fetchAnsweredQuestions();
    }, [groupId]);

    useEffect(() => {
        const fetchScannedQuestions = async () => {
            console.log('groupId', groupId)
            const response = await fetch(`${backendDomain}/api/scanned-questions/${groupId}`);
            if (response.ok) {
                const data = await response.json();
                setScannedQuestions(data);
                scannedQuestionsRef.current = data;  // Update the ref with the latest data
            } else {
                alert('Failed to fetch scanned questions');
            }
        };

        fetchScannedQuestions();
    }, [groupId]);

    useEffect(() => {
        if (isScanning && videoRef.current) {
            const codeReader = new BrowserQRCodeReader();
            codeReaderRef.current = codeReader;
            const videoElement = videoRef.current;

            const stopVideoStream = () => {
                if (videoElement && videoElement.srcObject) {
                    const stream = videoElement.srcObject as MediaStream;
                    stream.getTracks().forEach(track => track.stop());
                    videoElement.srcObject = null;
                }
            };

            const decodeCallback = async (result: any, err: any, controls: any) => {
                if (!err && result) {
                    console.log("QR Code detected:", result.getText());
                    stopVideoStream(); // Stop the video stream immediately after a successful scan
                    setIsScanning(false);
                    controls.stop();
                    await handleScan(result.getText());
                }
                if (err) {
                    // You can choose to log this or suppress it
                }
            };

            const controls = codeReader.decodeFromVideoDevice(undefined, videoElement, decodeCallback).catch(err => {
                console.error("Error initializing QR Code scanner:", err);
            });

            return () => {
                stopVideoStream();
                codeReaderRef.current = null;
            };
        }
    }, [isScanning]);

    const handleScan = useCallback(async (questionTypeHash: string) => {
        console.log("Handling scan for data:", questionTypeHash);

        try {
            const response = await fetch(`${backendDomain}/api/get-question`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    token: questionTypeHash,
                    groupId: groupId
                })
            });

            if (!response.ok) {
                throw new Error('Failed to fetch question');
            }

            const data = await response.json();
            const question = data.question;

            // Check if the question is already scanned before storing
            if (scannedQuestionsRef.current.some(q => q.id === question.id)) {
                console.log('This question has already been scanned.');
                setIsScanning(false);
                return;
            }

            // Store scanned question
            const storeResponse = await fetch(`${backendDomain}/api/store-scanned-question`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ groupId: groupId, questionId: question.id })
            });

            if (storeResponse.ok) {
                setScannedQuestions(prev => {
                    const updatedQuestions = [...prev, question];
                    scannedQuestionsRef.current = updatedQuestions;  // Update the ref with the new question
                    return updatedQuestions;
                });
            } else {
                const errorData = await storeResponse.json();
                console.log('Failed to store scanned question: ' + errorData.error);
                setIsScanning(false);
            }
        } catch (error) {
            console.error('Error handling scan:', error);
            setIsScanning(false);
        }
    }, [groupId]);

    const toggleScanner = () => {
        setIsScanning(!isScanning);
    };

    const answeredQuestionIds = new Set(answeredQuestions.map(question => question.id));

    return (
        <div>
            <h1>Dashboard</h1>
            <h2>Answered Questions</h2>
            <ul>
                {answeredQuestions.map(question => (
                    <li key={question.id}>
                        {question.title}
                    </li>
                ))}
            </ul>

            <button onClick={toggleScanner}>
                {isScanning ? 'Stop Scanning' : 'Scan QR Code'}
            </button>
            {isScanning && <video ref={videoRef} style={{ width: '100%' }} />}

            <h2>Scanned Questions</h2>
            <ul>
                {scannedQuestions.map(question => (
                    <li key={question.id}>
                        {answeredQuestionIds.has(question.id) ? (
                            <span>{question.title} Answered</span>
                        ) : (
                            <a href={`/question/${question.id}`}>{question.title}</a>
                        )}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Dashboard;
